"use client";

import { useEffect } from "react";

declare global {
  interface Window {
    Optanon: any;
  }
}

export function useOnetrustTrigger() {
  useEffect(() => {
    const onClick = (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.classList.contains("onetrust-trigger") && window.Optanon) {
        e.preventDefault();
        window.Optanon.ToggleInfoDisplay();
      }
    };
    document.body.addEventListener("click", onClick);

    return () => {
      document.body.removeEventListener("click", onClick);
    };
  }, []);
}
