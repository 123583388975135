//import { register } from "@ebay/nice-modal-react";
import { SetupEventTracking } from "@up/analytics";
// import {
//   LeadCaptureTopicsv0Modal,
//   SetupModalTriggers,
//   useModalTimer,
// } from "@up/lead-capture";
import { isCartOpen } from "@up/cart";
import { useCart } from "@up/checkout";
import { Navigation } from "@up/navigation";
import {
  AttentionBanner,
  Main,
  NavigationProfileSelector,
  PreviewBanner,
  useOnetrustTrigger,
} from "@up/ui";

import Footer from "./Footer";

import type { ReactNode } from "react";

type Props = {
  attentionBanner?: {
    classes: string;
    text: any;
    theme: string;
  };
  children: ReactNode;
  navigation?: any;
  pageClasses?: string;
  preview?: boolean;
};

// register("lead-capture-topics-v0", LeadCaptureTopicsv0Modal);
// declare global {
//   interface Window {
//     topics: Array<string>;
//   }
// }

// if (typeof window !== "undefined") {
//   window.topics = [
//     "Business",
//     "Law and Government",
//     "Medicine",
//     "STEM",
//     "Other",
//   ];
// }

export default function Layout({
  attentionBanner,
  children,
  navigation,
  pageClasses,
  preview,
}: Props) {
  const cart = useCart();
  useOnetrustTrigger();
  // useModalTimer({
  //   modalID: "lead-capture-topics-v0",
  // });

  return (
    // <SetupModalTriggers>
    <SetupEventTracking
      className={`flex min-h-dvh flex-col ${pageClasses || ""}`}
    >
      {preview ? <PreviewBanner /> : null}

      <AttentionBanner
        className={`py-3 theme-white ${attentionBanner?.classes ?? ""}`}
        field={attentionBanner?.text}
      />

      <Navigation
        cartVariant="button-red"
        items={navigation?.data?.links || []}
        logo="/logo-header.png"
        logoAlt="Georgetown University Logo"
        profileVariant="button-white"
        signInVariant="button-link"
      />

      <NavigationProfileSelector
        cartCount={cart?.lineItems?.length}
        openCart={() => {
          isCartOpen.set(true);
        }}
      />

      <Main>{children}</Main>

      <Footer />
    </SetupEventTracking>
    // </SetupModalTriggers>
  );
}
